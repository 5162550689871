// This should match the server and react-client. I couldn't make a symlink.
const Permissions: {
  SUPER_CONFIGURATION: 'Admin',
  Supporter: 'Support',
} = {
  SUPER_CONFIGURATION: 'Admin',
  Supporter: 'Support',
}

export default Permissions

export type PossiblePermissionStrings = typeof Permissions[keyof typeof Permissions]
