export interface Solution {
  id: number
  name: string
}
export interface ImpactLevel {
  id: number
  name: string
}
export interface Browser {
  id: number
  name: string
}

export interface Status {
  id: number
  name: string
}
export interface Type {
  id: number
  name: string
}
export interface Role {
  id: number
  name: string
}
export interface TicketType {
  id: number
  name: string
}
const impacting: Solution = {
  id: 1,
  name: 'Impacting'
}

const solutions: Solution[] = [
  impacting
]

const low: ImpactLevel = {
  id: 1,
  name: 'Low'
}

const medium: ImpactLevel = {
  id: 2,
  name: 'Medium'
}

const high: ImpactLevel = {
  id: 3,
  name: 'High'
}

const impactLevels: ImpactLevel[] = [
  low,
  medium,
  high
]

const chrome: Browser = {
  id: 1,
  name: 'Google Chrome'
}
const firefox: Browser = {
  id: 2,
  name: 'Firefox'
}
const edge: Browser = {
  id: 3,
  name: 'Microsoft edge'
}
const ie: Browser = {
  id: 4,
  name: 'Internet explorer'
}

const browsers: Browser[] = [
  chrome,
  firefox,
  edge,
  ie
]

const solutionsWithNone: Solution[] = [{id: 1, name: 'None'}, impacting]

const statuses: Status[] = [
  {id: 1, name: 'Open'},
  {id: 2, name: 'Work in progress'},
  {id: 3, name: 'Closed'}
]
const types: Type[] = [
  {id: 1, name: 'Solution'},
  {id: 2, name: 'Browser'},
  {id: 3, name: 'ImpactLevel'}
  
]

const roles: Role[] = [{id: 1, name: 'Admin'}, {id: 2, name: 'Support'}]

const ticketTypes: TicketType[] = [{id: 1, name: 'Bug'}, {id: 2, name: 'Enhancement'}]

const userPoolId: string = 'eu-west-2_M4nqY5Z1c'
const clientId: string = '5j22lpe7hml4hkvaiha0lf930t'
const identityPoolId: string = 'eu-west-2:80bd0df0-b8d4-40b7-a041-4e3d3a2e8a81'
export {
  solutions,
  impactLevels,
  browsers,
  statuses,
  types,
  roles,
  solutionsWithNone,
  userPoolId,
  clientId,
  identityPoolId,
  ticketTypes
}