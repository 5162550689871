import React, { Component } from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const drawerWidth = 240

const styles = (theme: Theme) => createStyles({
  footer: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
    width: '100%',
    zIndex: 1000
  },
  internal: {
    textAlign: 'right',
    lineHeight: 'normal',
    color: theme.palette.primary.contrastText,
    paddingLeft: 0,
    paddingRight: 30,
    margin: 10
  },
  copyright: {
    float: 'left',
    textAlign: 'left',
    lineHeight: 'normal',
    color: theme.palette.primary.contrastText,
    paddingLeft: 95,
    paddingRight: 30,
    margin: 10,
    transition: theme.transitions.create(['padding', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    paddingLeft: 30,
    float: 'left',
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['padding', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
})

/* Make the style of hyperlinks white */
const LinkStyle = {
  color: 'white',
  verticalAlign: 'middle'
}

class Footer extends Component<WithStyles & { open: boolean }> {
  render () {
    const { classes } = this.props

    return (
      <footer className={classes.footer}>
        <Typography className={classNames(classes.copyright, { [classes.appBarShift]: this.props.open })}>
          © Copyright 2019 Fujitsu | Fujitsu Restricted - Internal Use Only
        </Typography>
        <Typography className={classes.internal}>
          <a href='http://www.fujitsu.com/global/about/' style={LinkStyle}>About</a> | <a href='https://www.fujitsu.com/global/about/resources/privacy/' style={LinkStyle}>Privacy</a> | <a href='https://www.fujitsu.com/global/about/resources/terms/index.html' style={LinkStyle}>Terms of Use</a>
        </Typography>
      </footer>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Footer)
