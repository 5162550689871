import React from 'react'
import PaperSheet from '../components/common/Paper'
import BuildIcon from '@material-ui/icons/Build'
import GroupIcon from '@material-ui/icons/Group'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import HistoryIcon from '@material-ui/icons/History'
import HelpIcon from '@material-ui/icons/Help'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Cardify from '../components/common/Cardify'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing() * 2
  }
})

interface Props {
}

class Home extends React.Component {
  render () {
    return (
      <PaperSheet helmetTitle='Home'>
        <Grid container spacing={10} alignItems='flex-end'>
          <Cardify to='/createreport' id='reportLink' label='New Ticket' iconLabel={DescriptionIcon} background='#2874D0' />
          <Cardify to='/myreports' id='myReports' label='My Tickets' iconLabel={GroupIcon} background='#9C1EB4' />
          {hasPermissions([Permissions.SUPER_CONFIGURATION, Permissions.Supporter]) && <Cardify to='/mysupport' id='mySupport' label='My Support' iconLabel={GroupIcon} background='#4B27B0' />}
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='/config' id='configLink' label='Configuration' iconLabel={BuildIcon} background='#D02863' />}
        </Grid>
      </PaperSheet>
    )
  }
}
export default withStyles(styles, { withTheme: true })(Home)
