import React from 'react'
import { Form } from 'formik'
import FormikTextField from './common/FormikTextField'
import SubmitButton from './common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from './common/CustomFormik'
import SmartSelectorField from './common/SmartSelect'
import { types, statuses } from './Data'

interface Props {
  onSubmit: (values: EvaluationFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: any
  isDisabled: boolean
}

export interface EvaluationFields {
  Status: string
  Evaluation: string
}

export default class EvaluationForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Status: '',
          Evaluation: ''
        } as EvaluationFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={2} style={{padding: 20}}>
              <SmartSelectorField name='Status' label='Status' data={statuses} xs={12} required={false}/>
              <FormikTextField name='Evaluation' label='Evaluation' variant='outlined' id='namefield' xs={12} multiline inputProps={ { maxLength: 500 } } rows={10}  required/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
