import React from 'react'
import PaperSheet from '../components/common/Paper'
import BuildIcon from '@material-ui/icons/Build'
import GroupIcon from '@material-ui/icons/Group'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import HistoryIcon from '@material-ui/icons/History'
import HelpIcon from '@material-ui/icons/Help'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Cardify from '../components/common/Cardify'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing() * 2
  }
})

interface Props {
}

class Config extends React.Component {
  render () {
    return (
      <PaperSheet helmetTitle='Configuration'>
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) &&<Grid container spacing={10} alignItems='flex-end'>
          <Cardify to='/config/attributes' id='configAttributes' label='Manage attributes' iconLabel={DescriptionIcon} background='#2874D0' />
          <Cardify to='/config/users' id='configUsers' label='Manage Users' iconLabel={GroupIcon} background='#9C1EB4' />
          <Cardify to='/config/reports' id='configReports' label='Manage Tickets' iconLabel={GroupIcon} background='#4B27B0' />
        </Grid>}
      </PaperSheet>
    )
  }
}
export default withStyles(styles, { withTheme: true })(Config)
