/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:80bd0df0-b8d4-40b7-a041-4e3d3a2e8a81",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_M4nqY5Z1c",
    "aws_user_pools_web_client_id": "1ane7s7fopjjc0t780h9vn2uic",
    "oauth": {}
};


export default awsmobile;
