import React, { FormEvent } from 'react';
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import {Dialog, DialogTitle, Grid, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import Snackbar from '../components/common/Snackbar';
import CustomButton from '../components/common/buttons/CustomButton'
import { passwordCheck } from '../components/utils/ValidationFunctions';
import ChangePassword from '../components/AuthFunctions/ChangePassword';
import ForgotPassword from '../components/AuthFunctions/ForgotPassword';
import ConfirmForgotPassword from '../components/AuthFunctions/ConfirmForgotPassword';

const styles = (theme: Theme) => createStyles({
  dialog: {
    padding: theme.spacing(3),
    overflow: 'hidden'
  },
  requestDiv: {
    padding: theme.spacing(3),
    alignItems: 'center'
  }
})

interface Props extends WithStyles{
  open: boolean
  handleClose: () => void
}

interface State {
  processing: boolean
  error: null | string
  code: string
  codeSent: boolean
  newPassword: string
  confirmPassword: string
  success: null | string
}

class PasswordReset extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      processing: false,
      error: null,
      newPassword: '',
      code: '',
      codeSent: false,
      confirmPassword: '',
      success: null
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  CodeRequest = async () => {
    try {
      await ForgotPassword(window.sessionContext.userName)
      this.setState({codeSent: true, success: 'Code sent'})
    } catch (error) {
      this.setState({error: 'Code not sent'})
    }
  }

  async onSubmit (e: FormEvent) {
    e.preventDefault()
    if (this.state.newPassword === this.state.confirmPassword) {
      var pCheck = passwordCheck(this.state.newPassword)
      if (pCheck === undefined) {
        try {
          var result = await ConfirmForgotPassword(window.sessionContext.userName,this.state.newPassword, this.state.code)
          this.setState({success: 'Password changed'})
          this.props.handleClose()
        } catch (error) {
          this.setState({error: 'Could not change password'})
        }
      }
      this.setState({error: pCheck!})
    } else {
      this.setState({error: 'Passwords do not match'})
    }
  }
  render () {
    const {classes} = this.props
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open} className={classes.dialog} style={{overflow: 'hidden'}}>
        <DialogTitle id="simple-dialog-title">Reset Password</DialogTitle>
          <form style={{width: '100%'}} onSubmit={this.state.processing ? undefined : this.onSubmit}>
          {this.state.codeSent && <React.Fragment>
              <Grid container spacing={2} style={{padding: 20, overflow: 'hidden'}}>
                <Grid item xs={12}>
                  <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='code'>Code</InputLabel>
                    <Input id='code' name='code' type='number' autoComplete='code' autoFocus value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl margin='normal' required fullWidth>
                    <InputLabel htmlFor='newPassword'>New Password</InputLabel>
                    <Input name='newPassword' type='password' id='newPassword' autoComplete='new password' value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl margin='normal' required fullWidth>
                    <InputLabel htmlFor='confirm password'>Confirm Password</InputLabel>
                    <Input name='confirm password' type='password' id='confirm password' autoComplete='confirm-password' value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                  >
                    Confirm Reset
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>} {!this.state.codeSent && <div style={{padding: '15'}} className={classes.requestDiv}> <CustomButton title='Request Code' onClick={this.CodeRequest}/> </div>}
          </form>
          {this.state.error && <Snackbar message={this.state.error} isError/>}
          {this.state.success && <Snackbar message={this.state.success}/>}
      </Dialog>
    )
  }
}

export default withStyles(styles)(PasswordReset)