import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import DialogViewer from '../common/DialogViewer'

interface Props extends RouteComponentProps {
  report: Report
}

function ReportExpansionTableRow(props: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TableRow  hover style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <TableCell align='center'>{moment(props.report.DateTime).format('DD/MM/YYYY')}</TableCell>
        <TableCell align='center'>{props.report.Name}</TableCell>
        <TableCell align='center'>{props.report.Impact}</TableCell>
        <TableCell align='center'>{props.report.Status}</TableCell>
      </TableRow>
      <DialogViewer report={props.report} handleClose={handleClose} open={open} />
    </React.Fragment>
  )
}

export default withRouter(ReportExpansionTableRow)
