import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Divider, Theme, createStyles, WithStyles} from '@material-ui/core'
import {Link} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing() * 2
  },
  link: {
    color: '#fff',
    textDecoration: 'none'
  }
})

interface Props extends WithStyles {
  background: string
  to: string
  label: string
  iconLabel: React.ComponentType<SvgIconProps> | string
  id?: string
}

class Cardify extends React.Component<Props> {
  render () {
    const { classes } = this.props
    return (
      <Grid item xs={6} sm={3}>
        <Card id={this.props.id} raised className={classes.card} style={{ background: this.props.background, color: '#fff' }}>
          <CardActionArea >
            <Link to={this.props.to} className={classes.link}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant='h6' style={{ color: '#fff', textAlign: 'center' }}>{this.props.label}</Typography>
                <Divider style={{ color: '#000' }} />
                {typeof this.props.iconLabel === 'string' ? <span style={{ fontSize: 79, fontWeight: 'bolder' }}>{this.props.iconLabel}</span> : <this.props.iconLabel style={{ fontSize: 100 }} />}
              </CardContent>
            </Link>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
}

export default withStyles(styles)(Cardify)
