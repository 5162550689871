import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

interface SimpleDialogProps {
  open: boolean;
  handleClose (): void
  title: string
  children: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  dialog: {
    padding: theme.spacing(10),
    overflow: 'hidden',
    width: theme.spacing() * 300,
    position: 'absolute',
    margin: 'auto'
  },
}))

export default function SimpleDialog(props: SimpleDialogProps) {
  const classes = useStyles()
  const { handleClose, open, title, children } = props;



  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      {children}
    </Dialog>
  );
}