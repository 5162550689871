import { CognitoAccessToken } from 'amazon-cognito-identity-js'
import React, { Component } from 'react'
import SessionContext, { LocalTokenKey } from '../../SessionContext'
import GetUserAttributes from '../AuthFunctions/GetUserAttributes'
import GetUserData from '../AuthFunctions/GetUserData'
import { Auth } from 'aws-amplify'
import SignOut from '../AuthFunctions/SignOut'
interface State {
  token: TokenResponse | null,
  hasAttemptedLocalToken: boolean
  expired: boolean
  name: string
  email: string
  userName: string
  isLoggedIn: boolean
  solution: string
  role: string
}

export interface TokenCognito {
  expiration: number
  jwt: string
  issuedAt: number
}

/**
 * This component is almost near the root, and will handle recovering existing
 * sessions and providing the sessionContext to components below it.
 */
export default class SessionProvider extends Component<{user: any, userObject: any}, State> {
  constructor (props: {user: any, userObject: any}) {
    super(props)
    this.state = {
      hasAttemptedLocalToken: false,
      token: null,
      expired: false,
      email: '',
      name: '',
      userName: '',
      isLoggedIn: false,
      role: '',
      solution: ''
    }
  }

  async componentDidMount () {
    var user = this.props.user
    var localTokenItem = user.signInUserSession.idToken.payload
    console.log(user)
    var userNameItem = localStorage.getItem('userName')
    this.assignUserName(userNameItem!)
    var nameItem = localStorage.getItem('name')
    if(nameItem!== null)this.setState({name: nameItem})
    var emailItem = localStorage.getItem('email')
    if(emailItem!== null)this.setState({email: emailItem})
    var solutionItem = localStorage.getItem('solution')
    if(solutionItem!== null)this.setState({solution: solutionItem})
    var roleItem = localStorage.getItem('role')
    if(roleItem!== null)this.setState({role: roleItem})
    if (!localTokenItem) return this.setState({ hasAttemptedLocalToken: true }) // No token?
    var localToken: TokenResponse = localTokenItem
    if (Date.now() / 1000 > parseInt(localToken.exp)) return await Auth.signOut()
    this.changeTokenObject(localToken) // Must be a good token!
    this.setState({isLoggedIn: true})
    localStorage.setItem('name', this.state.name)
  }

  changeTokenObject = (token: TokenResponse) => {
    this.setState({ token, hasAttemptedLocalToken: true, expired: false, isLoggedIn: true })
    localStorage.setItem(LocalTokenKey, JSON.stringify(token))
  }

  assignUserName = (userName: string) => {
    this.setState({userName: userName})
    localStorage.setItem('userName', userName)
  }

  logout = () => {
    Auth.signOut()
    this.setState({token: null})
    localStorage.removeItem(LocalTokenKey)
    window.location.reload()
  }

  setExpired = () => {
    this.setState({ expired: true })
  }

  render () {
    if (!this.state.hasAttemptedLocalToken) return null
    var isLoggedIn = this.state.token !== null
    var sessionContext = {
      token: this.state.token,
      changeTokenObject: this.changeTokenObject,
      assignUserName: this.assignUserName,
      logout: this.logout,
      isLoggedIn: isLoggedIn,
      expired: this.state.expired,
      setExpired: this.setExpired,
      userRole: this.state.role,
      userSolution: this.state.solution,
      email: this.state.email,
      name: this.state.name,
      userName: this.state.userName
    }
    window.sessionContext = sessionContext

    return (
      <SessionContext.Provider value={sessionContext}>
        {this.props.children}
      </SessionContext.Provider>
    )
  }
}
