import React from 'react'
import Paper from '@material-ui/core/Paper'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { Theme, Grid, Typography, Divider } from '@material-ui/core'
import Helmet from 'react-helmet'

const styles = (theme: Theme) => createStyles({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing() * 3,
    paddingBottom: theme.spacing() * 3,
    // 8em is an educated guess. Is there a defined height for the AppBar component? Similar with footer - it's *about* 3em.
    minHeight: 'calc(100vh - 8em)',
    marginTop: 70,
    marginLeft: 80,
    marginRight: 10,
    marginBottom: '4em',
    overflow: 'hidden',
  }
})

/**
 * A PaperSheet is the box for content on a page. It also handles setting the page title.
 * You may add elements to the right hand side of the title.
 */
interface Props extends WithStyles{
  title?: string
  rightSideContent?: React.ReactNode
  subtitle?: React.ReactNode
  helmetTitle?: string
  divider?: boolean
}

class PaperSheet extends React.Component<Props> {

  render () {
    const { classes, children } = this.props

    return (
      <Paper id='paperSheet' elevation={2} className={classes.paper}>
        <Helmet title= {this.props.helmetTitle ? this.props.helmetTitle : this.props.title} />
        <Grid container justify='space-between' alignItems='center'>
          <Grid>
            <Typography variant='h5' style={{ paddingBottom: 10, textAlign: 'left' }}>{this.props.title}</Typography>
          </Grid>
          {this.props.rightSideContent && <Grid style={{ paddingBottom: 10 }}>
            {this.props.rightSideContent}
          </Grid>}
        </Grid>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6' style={{ paddingBottom: 10, textAlign: 'left' }}>{this.props.subtitle}</Typography>
        </Grid>
        {this.props.divider && <Divider style={{ marginBottom: 20 }} />}
        <div>{children}</div>
      </Paper>
    )
  }
}

export default(withStyles(styles)(PaperSheet))