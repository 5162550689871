import React from 'react'
import Fab from '@material-ui/core/Fab'

interface Props{
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  title?: string
  id?: string
}

class CustomButton extends React.Component<Props> {
  render () {
    return (
        <Fab id={this.props.id} color='primary' variant='extended' onClick={this.props.onClick} style={{ backgroundColor: 'green', color: '#fff' }}>
          <React.Fragment>{this.props.title}</React.Fragment>
        </Fab>
    )
  }
}

export default CustomButton