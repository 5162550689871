import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { clientId, userPoolId } from "../Data";


export default function ForgotPassword(username: string) {
    var poolData = {
      UserPoolId: userPoolId, // Your user pool id here
      ClientId: clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var userData = {
    Username: username,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  var dataReturn= new Promise<any>((resolve, reject) => {
    if (cognitoUser !== null)
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
          resolve(data)
      },
      onFailure: function(err) {
          reject(err)
      },
    });
  })
  return dataReturn
}