import React from 'react'
import { PossiblePermissionStrings } from '../../permissionsList'
export function hasPermissions (allowedPermissions: PossiblePermissionStrings[]) {
  var permission = String(window.sessionContext.userRole)
  var hasPermission = false

  for (var allowedPermission of Array.from(allowedPermissions)) {
    if (permission === allowedPermission) {
      hasPermission = true
    }
    if (hasPermission) break
  }
  return hasPermission
}

const PermissionControl: React.FunctionComponent<{
  allowedPermissions: ['Admin', 'Support'],
  children: React.ReactNode,
  renderNoAccess?: React.ReactNode
}> = props => {
  const permitted = hasPermissions(props.allowedPermissions)
  if (permitted) {
    return <React.Fragment>{props.children}</React.Fragment>
  } else {
    return <React.Fragment>{props.renderNoAccess}</React.Fragment>
  }
}
export default PermissionControl
