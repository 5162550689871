import React from 'react'
import { Form } from 'formik'
import FormikTextField from './common/FormikTextField'
import SubmitButton from './common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from './common/CustomFormik'
import SmartSelectorField from './common/SmartSelect'
import { roles, solutions, solutionsWithNone, Solution } from './Data'
import { emailCheck } from './utils/ValidationFunctions'

interface Props {
  onSubmit: (values: UserConfigFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: UserConfigFields
  isDisabled: boolean
  solutions: Solution[]
}

export interface UserConfigFields {
  Email: string
  Role: string
  Solution: string
}

export default class UserConfigForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Email: '',
          Role: '',
          Solution: ''
        } as UserConfigFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={4} style={{padding: 20}}>
              <FormikTextField name='Email' label='Email' variant='outlined' id='emailfield' required xs={6} validate={emailCheck}/>
              <SmartSelectorField name='Role' label='Role' data={roles} xs={6} required/>
              <SmartSelectorField name='Solution' label='Solution' data={solutions} xs={6} required={false}/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}