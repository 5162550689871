import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import './App.css'
import Router from './components/Router'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import SessionProvider from './components/utils/SessionProvider'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Authenticator from '../src/components/Authenticator'
Amplify.configure(awsconfig)

/**
 * This is the entrypoint for the application. It doesn't do a lot.
 */

interface State {
  user: any
  userDetails: any
  authState: any
}
 export default function App() {
  const [user, setUser] = React.useState<any>({})
  const [userDetails, setuserDetails] = React.useState({})
  const [authState, setAuthState] = React.useState<any>({})
  const [darkState, setDarkState] = React.useState(true);
  const darkModeState = localStorage.getItem('darkMode')
  const palletType = darkModeState === 'On' ? "dark" : "light";

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#A30B1A'
      },
      secondary: {
        main: '#2196f3',
        contrastText: '#000'
      },
      type: palletType
    },
    typography: {
      fontFamily: '"fujitsu"'
    }
  })

  const handleThemeChange = () => {
    setDarkState(!darkState);
    toggle()
  };

  const toggle = () => {
    if (darkState === true){
      localStorage.setItem('darkMode', 'On')
    } else {
      localStorage.setItem('darkMode', 'Off')
    }
  }
  React.useEffect(() => {
    console.log(user)
    return onAuthUIStateChange(async (nextAuthState, authData) => {
      var data: any = authData
      if(!data) return
      if (!data.attributes) return
      setUser(data)
      setAuthState(nextAuthState)
      var response = await fetch(`https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/users/get?email=${data.attributes.email}`, {method: 'GET'})
      var userGet = await response.json()
      setuserDetails(userGet.users.Item)
      localStorage.setItem("role", userGet.users.Item.Role)
      localStorage.setItem("solution", userGet.users.Item.Solution)
      localStorage.setItem("email", userGet.users.Item.Email)
      localStorage.setItem("name", data.attributes.name)
      localStorage.setItem("userName", data.attributes.email)
    })
  })

    return  authState === AuthState.SignedIn && user ?(
      <ThemeProvider theme={theme}>
        <SessionProvider user={user} userObject={userDetails}>
          <Router handleToggle={handleThemeChange}/>
        </SessionProvider>
        <CssBaseline />
      </ThemeProvider>
) : (<Authenticator />)
    }
