import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import DialogViewer from '../common/DialogViewer'
import SimpleDialog from '../common/SimpleDialog'
import AttributesForm, {AttributesFields} from '../AttributeForm'

interface Props extends RouteComponentProps {
  attribute: Attribute
}

function AttributesTableRow(props: Props) {
  const [opens, setOpens] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
    setSubmitted(false)
    setSubmitting(false)
  };

  const onSubmit = async (values: AttributesFields) => {
    setSubmitting(true)
    var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/attributes/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    var content = await response.json()
    setError(content)
    setSubmitted(true)
    setSubmitting(false)
  }


  return (
    <React.Fragment>
      <TableRow  hover style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <TableCell align='center'>{props.attribute.Type}</TableCell>
        <TableCell align='center'>{props.attribute.Name}</TableCell>
      </TableRow>
      <SimpleDialog open={opens} handleClose={handleClose} title='Add new user'>
        <AttributesForm values={props.attribute} submitted={submitted} submitting={submitting} isDisabled={isDisabled} onSubmit={onSubmit}/>
      </SimpleDialog>
    </React.Fragment>
  )
}

export default withRouter(AttributesTableRow)
