import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import DialogTitle from '@material-ui/core/DialogTitle';


const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface props {
  report: Report
  handleClose: () => void
  open: boolean
}
export default function CustomizedDialogs(props: props) {
  const data : any = props.report

  return (
    <div>
      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title">
          {!props.report.TicketType ? 'Enhancement' : 'Bug'} Ticket - {props.report.DateTime}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <strong>Date Submitted</strong> - {moment.unix(props.report.DateTime).format('DD/MM/YYYY')}
          </Typography>
          <Typography gutterBottom>
            <strong>Solution</strong> - {props.report.Solution}
          </Typography>
          <Typography gutterBottom>
            <strong>Reported By</strong> - {props.report.Name}
          </Typography>
          {props.report.TicketType && <Typography gutterBottom>
            <strong>Impact Rating</strong> - {props.report.Impact}
          </Typography>}
          {props.report.TicketType && <Typography gutterBottom>
            <strong>Browser</strong> - {props.report.Browser}
          </Typography>}
          {props.report.TicketType && <Typography gutterBottom>
            <strong>URL</strong> - {props.report.URL}
          </Typography>}
          <Typography gutterBottom>
            <strong>Description</strong> - {props.report.Description}
          </Typography>
          {props.report.TicketType && <Typography gutterBottom>
            <strong>Expected Outcome</strong> - {data['Expected Outcome']}
          </Typography>}
          {props.report.TicketType && <Typography gutterBottom>
            <strong>Actual Outcome</strong> - {data['Actual Outcome']}
          </Typography>}
          <Typography gutterBottom>
            <strong>Number of images uploaded</strong> - {props.report.Uploaded}
          </Typography>
          <Typography gutterBottom>
            <strong>Status</strong> - {props.report.Status}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
