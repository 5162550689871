import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { clientId, userPoolId } from "../Data";


export default function ConfirmForgotPassword(username: string, newPassword: string, code: string) {
    var poolData = {
      UserPoolId: userPoolId, // Your user pool id here
      ClientId: clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var userData = {
      Username: username,
      Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  var dataReturn= new Promise<string>((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
          resolve('Password confirmed!')
      },
      onFailure(err) {
          reject(err)
      },
    });
  })
  return dataReturn
}