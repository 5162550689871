export function requiredTextFieldValidation (v: string | number | null) {
  return v !== null && v.toString().length > 0 ? undefined : 'This field is required.'
}
export function emailCheck (email: string | number | null): string | undefined {
  if (typeof email === 'number' || email === null) return 'Not a valid type'
  var regex = /[A-Za-z0-9._%+-]+@([A-Za-z]+[A-Za-z]+(?:^|\W))?fujitsu\.com(?:$|\W)/
  return regex.test(email) ? undefined : 'Please enter a valid email in the format of x..x@xx.fujitsu.com or x...x@fujitsu.com'
}
export function urlCheck (url: string | number | null): string | undefined {
  if (typeof url === 'number' || url === null) return 'Not a valid type'
  var regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&=]*)?/
  return regex.test(url) ? undefined : 'Please enter a valid url'
}
export function passwordCheck (password: string | number | null): string | undefined {
  if (typeof password === 'number' || password === null) return 'Not a valid type'
  var passChars = 0
  var passNumber = false
  var passCapital = false
  var passSpecial = false
  var error = ''
  for (var i = 0; i < password.length; i++) {
    passChars ++
    var char = password.charCodeAt(i)
    if (char >= 65 && char <= 90) {
      passCapital = true
    } else if (char >= 48 && char <= 57) {
      passNumber = true
    } else if ((char >= 33 && char <= 47) || (char >= 58 && char <= 64) || (char >= 91 && char <= 96) || (char >= 123 && char <= 126)) {
      passSpecial = true
    }
  }
  if (passChars < 8) {
    error += 'Password is not 8 characters or more. '
  }
  if (!passCapital) {
    error += 'Password does not contain a capital letter. '
  }
  if (!passNumber) {
    error += 'Password does not contain a number. '
  }
  if (!passSpecial) {
    error += 'Password does not contain a special character. These can include: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` '
  }
  if (error.length === 0) return undefined
  return error
}
