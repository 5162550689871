import React from 'react'
import { Route, BrowserRouter, Switch,  RouteProps, withRouter, HashRouter } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import BugFormPage from '../routes/BugFormPage'
import NavBar from './common/NavBar'
import Sidebar from './common/Sidebar'
import Footer from './common/Footer'
import HomePage from '../routes/HomePage';
import ConfigPage from '../routes/ConfigPage';
import MyReports from '../routes/MyReportsPage';
import AttributesConfig from '../routes/AttributesConfig';
import MySupport from '../routes/MySupportPage';
import ReportsConfig from '../routes/ReportsConfig';
import UserConfig from '../routes/UserConfig';
import { Backdrop } from '@material-ui/core';
import { SessionContextProps, withSessionContext } from '../SessionContext'
import { AuthState} from '@aws-amplify/ui-components'

interface Props extends SessionContextProps {
  handleToggle: () => void
}

class Router extends React.Component<Props>{
    
    render() {
        return(
            <HashRouter>
                <React.Fragment>
                {AuthState.SignedIn && <PageDecorations handleToggle={this.props.handleToggle}/>}
                    <ErrorBoundary>
                        <Switch>
                            <Route path='/' exact component={HomePage}/>
                            <Route path='/config' exact component={ConfigPage} />
                            <Route path='/createreport' exact component={BugFormPage}/>
                            <Route path='/myreports' exact component={MyReports} />
                            <Route path='/config/attributes' exact component={AttributesConfig} />
                            <Route path='/config/reports' exact component={ReportsConfig} />
                            <Route path='/config/users' exact component={UserConfig} />
                            <Route path='/mysupport' exact component={MySupport} />
                        </Switch>
                    </ErrorBoundary>
                </React.Fragment>
            </HashRouter>
        )
    }
}

export default withSessionContext(Router)

class PageDecorations extends React.Component<{handleToggle: () => void}, { open: boolean }> {
    state = {
      open: false
    }
    handleDrawerClose = () => {
      this.setState({ open: false })
    }
  
    handleDrawerOpen = () => {
      this.setState({ open: true })
    }
  
    render () {
      return (
        <React.Fragment>
          <NavBar open={this.state.open} handleDrawerOpen={this.handleDrawerOpen} handleToggle={this.props.handleToggle}/>
          <Backdrop style={{zIndex: 2}} open={this.state.open} onClick={this.handleDrawerClose}/>
          <Sidebar open={this.state.open} handleDrawerClose={this.handleDrawerClose} />
          <Footer open={this.state.open} />
        </React.Fragment>
      )
    }
  }
  
