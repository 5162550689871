import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import { RouteChildrenProps } from 'react-router'
import BugForm, { BugFields } from '../components/BugForm'
import EnhancementForm, { EnhancementFields } from '../components/EnhancementForm'
import PaperSheet from '../components/common/Paper'
import { Typography, LinearProgress, FormControl, InputLabel, Input, Select, Grid } from '@material-ui/core'
import Snackbar from '../components/common/Snackbar'
import { Solution, ImpactLevel, Browser, TicketType, ticketTypes } from '../components/Data'
import { MenuItem } from '@material-ui/core'

interface State {
  submitting: boolean,
  submitted: boolean,
  error: string | null,
  attributes: Attribute[]
  loading: boolean
  ticketType: any
}

export default class BugFormPage extends React.Component<RouteChildrenProps, State> {
  constructor(props: RouteChildrenProps) {
    super(props)
    this.state = {
      submitting: false,
      submitted: false,
      error: null,
      attributes: [],
      loading: false,
      ticketType: ''
    }
  }

  async componentDidMount() {
    this.setState({loading: true})
    var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
    var attributes = await response.json()
    this.setState({attributes: attributes.attributes.Items, loading: false})
  }


  onSubmit = async (values: BugFields) => {
    this.setState({ submitting: true, error: null })
    if (values.images.length === 0) {
      return this.setState({error: 'Please upload an image', submitting: false})
    }
    var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/submit', {
      body: JSON.stringify({
        ...values,
        email: window.sessionContext.userName,
        name: window.sessionContext.name,
        images: values.images.map(image => image.name),
        ticketType: 'bug'
      }), method: 'POST'
    })
    var content = await response.json()
    for(const image of values.images) {
      await fetch(content.fileUploadURLs[image.name],{method: 'PUT', body: image, headers: {'Content-Type':image.type}})
    }
    this.setState({ submitted: true, submitting: false })
  }

  onESubmit = async (values: EnhancementFields) => {
    this.setState({ submitting: true, error: null })
    if (values.images.length === 0) {
      return this.setState({error: 'Please upload an image', submitting: false})
    }
    var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/submit', {
      body: JSON.stringify({
        ...values,
        email: window.sessionContext.userName,
        name: window.sessionContext.name,
        images: values.images.map(image => image.name),
        ticketType: 'enhancement'
      }), method: 'POST'
    })
    var content = await response.json()
    for(const image of values.images) {
      await fetch(content.fileUploadURLs[image.name],{method: 'PUT', body: image, headers: {'Content-Type':image.type}})
    }
    this.setState({ submitted: true, submitting: false })
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ticketType: event.target.value});
  };

  render() {
    const solutions: Solution[] = sort(this.state.attributes, 'Solution', false)
    const impactLevels: ImpactLevel[] = sort(this.state.attributes, 'ImpactLevel', false)
    const browsers: Browser[] = sort(this.state.attributes, 'Browser', false)
    return (
      <PaperSheet helmetTitle='New Ticket' title='New Ticket'>
        {!this.state.submitted && !this.state.submitting && !this.state.loading && <Grid container>
          <Grid item xs={3}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='name'>Choose a Ticket Type</InputLabel>
              <Select id='ticketType' name='ticketType' autoComplete='Ticket Type' value={this.state.ticketType} onChange={(this.handleChange)}>
                {ticketTypes.map( t => (
                  <MenuItem value={t.id}>{t.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>}
        {!this.state.submitted && !this.state.submitting && !this.state.loading && this.state.ticketType === 2 && <EnhancementForm
          submitted={this.state.submitted}
          submitting={this.state.submitting}
          onSubmit={this.onESubmit}
          isDisabled={false}
          solutions={solutions}
        />}
        {!this.state.submitted && !this.state.submitting && !this.state.loading && this.state.ticketType === 1 && <BugForm
          submitted={this.state.submitted}
          submitting={this.state.submitting}
          onSubmit={this.onSubmit}
          isDisabled={false}
          solutions={solutions}
          impacts={impactLevels}
          browsers={browsers}
        />}{this.state.submitted && !this.state.submitting && <Typography align='center' variant='h2'>Thank you for submitting the form</Typography>}
        {this.state.submitting && !this.state.submitted && <LinearProgress/>}
        {this.state.error && <Snackbar isError message={this.state.error}></Snackbar>}
      </PaperSheet>
    )
  }
}

export function sort(data: Attribute[], type: string, withNone: boolean) {
  type attributeArray = {id: number, name: string}
  var array: attributeArray[] = []
  if (withNone) {
    var jt: attributeArray = {id: 0, name: 'None'}
    array.push(jt)
    for(var j = 0; j < data.length; j++) {
      var t: attributeArray
      if(data[j].Type === type) {
        t = {id: j+1, name: data[j].Name}
        array.push(t)
      }
    }
  } else {
    for(var j = 0; j < data.length; j++) {
      var t: attributeArray
      if(data[j].Type === type) {
        t = {id: j, name: data[j].Name}
        array.push(t)
      }
    }
  }
  return array
}