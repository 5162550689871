import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifySignIn, AmplifyConfirmSignIn, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      height: '100vh'
    },
  }),
);

export default function Authenticator() {
  const classes = useStyles()
  return (
    <AmplifyAuthenticator usernameAlias="email" className={classes.root}>
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        validationErrors={'Could not sign you up'}
        formFields={[
          {
            type: "name",
            label: "Name",
            placeholder: "Enter your name",
            required: true,
          },
          {
            type: "email",
            label: "Email",
            placeholder: "Enter your email",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Enter your password",
            required: true,
          },
        ]} 
      />
      <AmplifyConfirmSignUp slot="confirm-sign-up" usernameAlias="email"/>
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      <AmplifyConfirmSignIn headerText="Confirm Sign In" slot="confirm-sign-in"></AmplifyConfirmSignIn>
    </AmplifyAuthenticator>
  );
};