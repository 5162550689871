import React, { Attributes } from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import AttributesTable from '../components/List/AttributesTable'
import AttributesForm, { AttributesFields } from '../components/AttributeForm'
import AddButton from '../components/common/buttons/AddButton'
import SimpleDialog from '../components/common/SimpleDialog'
import UserConfigForm, { UserConfigFields } from '../components/UserConfigForm'
import UserConfigTable from '../components/List/UserConfigTable'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import { Solution } from '../components/Data'
import { sort } from './BugFormPage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    }
  })
)


export default function UserConfig() {
  const classes = useStyles();
  const [user, setuser] = React.useState<ManageUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)
  const [attributes, setAttributes] = React.useState<Attribute[]>([])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
    setSubmitted(false)
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/users/get', {method: 'GET'})
      var user = await response.json()
      var response2 = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
      var attributes = await response2.json()
      setAttributes(attributes.attributes.Items)
      setuser(user.users.Items)
    }
    getData().then(() => setLoading(false))
  }, [])

  const onSubmit = async (values: UserConfigFields) => {
    setSubmitting(true)
    try {
      var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/users/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    window.location.reload()
    } catch (error) {
      setSubmitted(false)
      setSubmitting(false)
    }
  }

  const solutions: Solution[] = sort(attributes, 'Solution', true)

  return (
    <PaperSheet title='Manage Users' divider rightSideContent={<AddButton  onClick={handleOpen} title='Add New User'/>}>
      {!loading && hasPermissions([Permissions.SUPER_CONFIGURATION]) &&<UserConfigTable user={user} solutions={solutions}/>}
      <SimpleDialog open={open} handleClose={handleClose} title='Add new user'>
        <UserConfigForm submitted={submitted} submitting={submitting} isDisabled={isDisabled} onSubmit={onSubmit} solutions={solutions}/>
      </SimpleDialog>
    </PaperSheet>
  )
}