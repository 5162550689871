import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import DialogViewer from '../common/DialogViewer'
import CustomButton from '../common/buttons/CustomButton'
import SimpleDialog from '../common/SimpleDialog'
import EvaluationForm, { EvaluationFields } from '../EvaluationForm'

interface Props {
  report: ReportWithEvaluation
}

function CustomExpansionTableRow(props: Props) {
  const [opens, setOpens] = React.useState(false);
  const [evaluate, setEvaluate] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)
  const [values, setValues] = React.useState({})

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleEvaluate = () => {
    setEvaluate(true);
  };

  const handleEvaluateClose = () => {
    setEvaluate(false);
    setSubmitted(false)
    setSubmitting(false)
  };

  const onSubmit = async (values: EvaluationFields) => {
    setSubmitting(true)
    try {
      var response = await fetch('https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/evaluation', {
      body: JSON.stringify({
        dateTime: props.report.DateTime,
        ...values
      }), method: 'POST'
    })
    var content = await response
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    window.location.reload()
    } catch (error) {
      setSubmitted(false)
      setSubmitting(false)
    }
  }


  return (
    <React.Fragment>
      <TableRow  hover>
        <TableCell align='center'>{moment(props.report.DateTime).format('DD/MM/YYYY')}</TableCell>
        <TableCell align='center'>{props.report.Name}</TableCell>
        <TableCell align='center'>{props.report.Impact}</TableCell>
        <TableCell align='center'>{props.report.Status}</TableCell>
        <TableCell align='center'>
          <CustomButton title='View Ticket' onClick={handleClickOpen}/>
        </TableCell>
        <TableCell align='center'>
          <CustomButton title='Evaluate' onClick={handleEvaluate}/>
        </TableCell>
      </TableRow>
      <DialogViewer report={props.report} handleClose={handleClose} open={opens} />
      <SimpleDialog open={evaluate} handleClose={handleEvaluateClose} title='Evaluate'>
        <EvaluationForm values={props.report}submitted={submitted} submitting={submitting} isDisabled={isDisabled} onSubmit={onSubmit}/>
      </SimpleDialog>
    </React.Fragment>
  )
}

export default CustomExpansionTableRow