import React from 'react'
import { Form } from 'formik'
import FormikTextField from './common/FormikTextField'
import SubmitButton from './common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from './common/CustomFormik'
import { emailCheck, urlCheck } from './utils/ValidationFunctions'
import SmartSelectorField from './common/SmartSelect'
import { solutions, impactLevels, browsers, Browser, Solution, ImpactLevel } from './Data'
import DropzoneUploader from './common/DropzoneUpload'

interface Props {
  onSubmit: (values: BugFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: BugFields
  isDisabled: boolean
  browsers: Browser[]
  solutions: Solution[]
  impacts: ImpactLevel[]
}

export interface BugFields {
  id?: number
  solution: string
  url: string
  description: string
  expectedOutcome: string
  actualOutcome: string
  impact: string
  browser: string
  images: File[]
}

export default class BugForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          solution: '',
          url: '',
          description: '',
          outcome: '',
          expectedOutcome: '',
          actualOutcome: '',
          impact: '',
          browser: '',
          images: []
        } as BugFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={2}>
              <SmartSelectorField name='solution' label='Solution' data={this.props.solutions} xs={3} required/>
              <SmartSelectorField name='impact' label='Impact Level' data={this.props.impacts} xs={3} required/>
              <SmartSelectorField name='browser' label='Browser' data={this.props.browsers} xs={3} required/>
              <FormikTextField name='url' label='URL' variant='outlined' inputProps={ { maxLength: 100 } } multiline id='urlfield' required validate={urlCheck} xs={3}/>
              <FormikTextField name='description' label='Bug description' placeholder='Write a bug description and also include the steps to reproduce the bug.' variant='outlined' inputProps={ { maxLength: 400 } } multiline id='descriptionfield' rows={10} required/>
              <FormikTextField name='expectedOutcome' label='Expected Outcome' variant='outlined' inputProps={ { maxLength: 400 } } multiline id='expectedOutcomefield' rows={10} required/>
              <FormikTextField name='actualOutcome' label='Actual Outcome' variant='outlined' inputProps={ { maxLength: 400 } } multiline id='actualOutcomefield' rows={10} required/>
              <DropzoneUploader name='images'/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
