import React, { Component } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { Grid } from '@material-ui/core'
import { FieldConfig, Field, FieldProps } from 'formik'

/**
 * A Formik-compatible field that allows a role type to be selected. It returns a number type - not a string!
 */
export default class DropzoneUploader extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={DropzoneUpload} />
    )
  }
}

interface Props{
}

class DropzoneUpload extends React.Component<Props & FieldProps>{
  handleChange(files: File[]){
    this.props.form.setFieldValue(this.props.field.name, files, false)
  }
  render(){
    return (
      <Grid item xs={12}>
        <DropzoneArea
          onChange={this.handleChange.bind(this)}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          maxFileSize={5000000}
        />
      </Grid>
    )
  }
}
