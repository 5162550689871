import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import {Grid} from '@material-ui/core'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ReportExpansionTable from '../components/List/ReportExpansionTable'
import { RouteComponentProps, withRouter } from 'react-router'
import AddButton from '../components/common/buttons/AddButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    count: {
      fontSize: theme.typography.pxToRem(15),
      right: 0,
      paddingLeft: theme.spacing(30),
      color: theme.palette.text.secondary,
      alignSelf: 'right'
    }
  })
)

interface props extends RouteComponentProps{
}


function MyReports(props: props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [reportGroups, setReportGroups] = React.useState<StatusReportGroup[]>([]);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const groupFilter = (data: Report[]) => {
    var groups: StatusReportGroup[] = [{name: 'Open', reports: []}, {name: 'Work in progress', reports: []}, {name: 'Closed', reports: []} ]
    for (var i = 0; i <data.length; i++) {
      var currentData = data[i]
      for (var j = 0; j < groups.length; j++) {
        if (data[i].Status === groups[j].name) {
          groups[j].reports.push(currentData)
        }
      }
    }
    return groups
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch(`https://o0p1dnl2mk.execute-api.eu-west-2.amazonaws.com/main/reports?email=${window.sessionContext.userName}`, {method: 'GET'})
      var reports = await response.json()
      var reportGrouping = groupFilter(reports.reports.Items)
      setReportGroups(reportGrouping)
    }
    getData()
    setLoading(false)
  }, [])

  return (
    <PaperSheet title='My Tickets' rightSideContent={<AddButton  onClick={() => props.history.push('/createreport')} title='Create new report'/>}>
      {!loading && <Grid container spacing={2} alignItems='flex-end'>
        {reportGroups.map((group) => {
          return(
            <Grid item xs={12}>
              <ExpansionPanel expanded={expanded === group.name} onChange={handleChange(group.name)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>{group.name}</Typography>
                  <Typography className={classes.secondaryHeading}>Expand to view all tickets for this status</Typography>
                  <Typography className={classes.count}>Number of tickets: {group.reports.length}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ReportExpansionTable reports={group.reports}/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          )
        })}
      </Grid>}
    </PaperSheet>
  )
}

export default withRouter(MyReports)