import React from 'react'
import Fab from '@material-ui/core/Fab'
import Add from '@material-ui/icons/Add'

interface Props{
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  title?: string
  id?: string
}

class AddButton extends React.Component<Props> {
  render () {
    return (
        <Fab id={this.props.id} variant='extended' color='primary'  onClick={this.props.onClick} style={{ backgroundColor: 'green', color: '#fff' }}>
          <Add />{this.props.title}
        </Fab>
    )
  }
}

export default AddButton
